<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="body-title">
        <div class="body-logo">
          <img src="../assets/images/logo.png" alt="">
        </div>
        </div>
       <!--  <div class="body-logo-rt">
          <img src="../assets/images/tbea_logo.png" alt=""> 
          <div><span class="text">{{dateYear}} {{dateWeek}} {{dateDay}}</span></div>
        </div> -->
        <!-- 左侧 -->
        <div class="main-left">
          <div class="main-lf-top">
            <div class="title">设备故障统计</div>
            <CentreLeft1Chart/>
          </div>
          <div class="main-lf-cent">
            <div class="title">设备使用情况统计</div>
            <CentreLeft2Chart />
          </div>
          <div class="main-lf-bot">
            <div class="title title-bot">设备状态</div>
            <div class="malfunction">
              <div class="malfunction-lf">
                <CentreLeft3Chart :data="deviceStatusData"/>
              </div>
              <div class="malfunction-cent">
                <CentreLeft4Chart :data="deviceStatusData"/>
              </div>
              <div class="malfunction-rt">
                <img v-if="deviceStatusData[0].RunStatu==0" src="../assets/images/greenlight.png" alt="">
                <img v-else src="../assets/images/redlight.png" alt="">
                <p class="rt-text">运行状态</p>
                <p class="rt-status">({{deviceStatusData[0].RunStatu==0?'正常':'故障'}})</p>
              </div>
            </div>
          </div>      
        </div>

        <!-- 中间 -->
        <div class="main-center">
          <div class="main-cent-top">
            <div class="title">SMT产线</div>
            <div class="line-all" :class="{'line-all-first': i == 0}" v-for="(item, i) in smtList" :key="i">
              <div class="line-title"><img src="../assets/images/little.png">
                <span>{{item.title}}</span>
              </div>
              <div class="line-cont-box">
                <span class="smt">
                  <img src="../assets/images/smt.png" />
                </span>
                <span class="two-lights">
                  <span class="light-green"></span>
                  <span class="light-red"></span>
                </span>
              </div>
            </div>
            <!-- <div class="line-all" :class="{'line-all-first': i == 0}" v-for="(item, i) in lineList" :key="i">
              <div class="line-title"><img src="../assets/images/little.png">{{item[0].workcenter}}</div>
              <div class="line-cont-box">
                <ul ref="lineUl" :style="{'width': ''+((item.length)*3*2)+'rem','animation': 'scrollimg '+(item.length*4)+'s linear infinite'}" 
                  :class="{'addAnimation': item.length>3, 'noAnimation': item.length <= 3}">
                  <li v-for="(lis, v)  in item" :key="v" :class="{'yellowBg': lis.status == 1, 'redBg': lis.status == 2}">
                    <div class="line-name">
                      <span class="no"><div>{{lis.No}}#</div></span>
                      <span class="name">{{lis.Name}}</span>
                    </div>
                    <div class="line-in">运行时间：{{lis.ServiceTime}}</div>
                    <div class="line-in">停机时间：{{lis.StopTime}}</div>
                    <div class="line-in">稼动率：{{lis.Percent}}</div>
                    <div class="line-status" :class="{'yellowStu': lis.status == 1, 'redStu': lis.status == 2}">
                      <span :class="{'circlYellow': lis.status == 1, 'circleRed': lis.status == 2}"></span>
                      <span>状态：</span>
                      <span>{{checkStatus[lis.status]}}</span>
                    </div>
                    <img class="deviceImg" src="../assets/images/device1.png" alt="">
                  </li>
                  <li v-for="(lis, v)  in item" :key="v+'c'" :class="{'yellowBg': lis.status == 1, 'redBg': lis.status == 2}">
                    <div class="line-name">
                      <span class="no"><div>{{lis.No}}#</div></span>
                      <span class="name">{{lis.Name}}</span>
                    </div>
                    <div class="line-in">运行时间：{{lis.ServiceTime}}</div>
                    <div class="line-in">停机时间：{{lis.StopTime}}</div>
                    <div class="line-in">稼动率：{{lis.Percent}}</div>
                    <div class="line-status" :class="{'yellowStu': lis.status == 1, 'redStu': lis.status == 2}">
                      <span :class="{'circlYellow': lis.status == 1, 'circleRed': lis.status == 2}"></span>
                      <span>状态：</span>
                      <span>{{checkStatus[lis.status]}}</span>
                    </div>
                    <img class="deviceImg" src="../assets/images/device1.png" alt="">
                  </li>
                </ul>
                
              </div> 
              </div> -->
            <!-- <div class="page-tag">
              <span v-for="(tag, k) in pageTag" :key="k" :class="{'isActive': linePage-1==k}">产线{{tag}}</span>
            </div> -->
          </div>
          <div class="main-cent-bot">
            <div class="title title-bot">设备数量</div>
            <div class="cent-bot-div">
              <div class="cent-bot-div-lf">
                <img src="../assets/images/equipment_green.png" alt="">
                <div class="rf">
                  <div>在用设备数量(台)</div>
                  <div class="num">{{deviceNum['UseQty']}}</div>
                </div>
              </div>
              <div class="cent-bot-div-cent">
                <img src="../assets/images/equipment_yellow.png" alt="">
                <div class="rf">
                  <div>异常设备数量(台)</div>
                  <div class="num">{{deviceNum['AnomaliesQty']}}</div>
                </div>
              </div>
              <div class="cent-bot-div-rf">
                <img src="../assets/images/equipment_red.png" alt="">
                <div class="rf">
                  <div>停用设备数量(台)</div>
                  <div class="num">{{deviceNum['StopQty']}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 右侧 -->
        <div class="main-right">
          <div class="main-lf-top main-right-top">
            <div class="title">设备使用负荷前五</div>
            <div v-for="(item, i) in device" :key="i" class="item-line">
              <div class="line-text"><span>{{item.Name}}</span><span>{{item.LoadTime}}</span></div>
              <div class="line-bg"><div class="line-per-bg" :style="{'width': ''+item.Rate+'%'}"></div></div>
            </div>
          </div>
          <div class="main-lf-cent main-right-cent">  
            <div class="title">设备异常趋势统计</div>
            <CentreRight2Chart1 />
          </div>
          <div class="main-lf-bot main-right-bot">
            <div class="title title-bot">设备性能</div>
            <div class="right-bot-wrap">
              <div class="right-bot-item">
                <div class="loop-pie">
                    <div class="loop-pie-line loop-pie-r">
                        <div class="loop-pie-c loop-pie-rm" :style="{'-webkit-transform': 'rotate('+loadPercent(40,100)[1]+'deg)'}"></div>
                    </div>
                    <div class="loop-pie-line loop-pie-l">
                        <div class="loop-pie-c loop-pie-lm" :style="{'-webkit-transform': 'rotate('+loadPercent(40,100)[0]+'deg)'}"></div>
                    </div>
                </div>
                <div class="text text1">
                  <p class="text-one">OEE</p>
                  <p class="text-two">(设备综合效率)</p>
                </div>
                <div class="text-number"><span>{{devicePreData[0]['OEE']}}</span>%</div>
              </div>
              <div class="right-bot-item">
                <div class="loop-pie">
                    <div class="loop-pie-line loop-pie-r">
                        <div class="loop-pie-c loop-pie-rm rm2" :style="{'-webkit-transform': 'rotate('+loadPercent(100,100)[1]+'deg)'}"></div>
                    </div>
                    <div class="loop-pie-line loop-pie-l">
                        <div class="loop-pie-c loop-pie-lm lm2" :style="{'-webkit-transform': 'rotate('+loadPercent(100,100)[0]+'deg)'}"></div>
                    </div>
                </div>
                <div class="text text2">
                  <p class="text-one">MTTR</p>
                  <p class="text-two">(平均修复时间)</p>
                </div>
                <div class="text-number"><span>{{devicePreData[0]['MTTR']}}</span>h</div>
              </div>
              <div class="right-bot-item">
                <div class="loop-pie">
                    <div class="loop-pie-line loop-pie-r">
                        <div class="loop-pie-c loop-pie-rm rm3" :style="{'-webkit-transform': 'rotate('+loadPercent(100,100)[1]+'deg)'}"></div>
                    </div>
                    <div class="loop-pie-line loop-pie-l">
                        <div class="loop-pie-c loop-pie-lm lm3" :style="{'-webkit-transform': 'rotate('+loadPercent(100,100)[0]+'deg)'}"></div>
                    </div>
                </div>
                <div class="text text3">
                  <p class="text-one">MTTF</p>
                  <p class="text-two">(平均故障时间)</p>
                </div>
                <div class="text-number"><span>{{devicePreData[0]['MTTF']}}</span>h</div>
              </div>
              <div class="right-bot-item">
                <div class="loop-pie">
                    <div class="loop-pie-line loop-pie-r">
                        <div class="loop-pie-c loop-pie-rm rm4" :style="{'-webkit-transform': 'rotate('+loadPercent(100,100)[1]+'deg)'}"></div>
                    </div>
                    <div class="loop-pie-line loop-pie-l">
                        <div class="loop-pie-c loop-pie-lm lm4" :style="{'-webkit-transform': 'rotate('+loadPercent(100,100)[0]+'deg)'}"></div>
                    </div>
                </div>
                <div class="text text4">
                  <p class="text-one">MTBF</p>
                  <p class="text-two">(平均故障间隔时间)</p>
                </div>
                <div class="text-number text-number-last"><span>{{devicePreData[0]['MTBF']}}</span>h</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="footer-wrap">
          <div class="footer">
            <div v-for="(item, i) in footerList" :key="i">
              {{item}}
            </div>
            <div v-for="(item, i) in footerList" :key="i+'c'">
              {{item}}
            </div>
          </div>
        </div> -->
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { formatTime } from '../utils/index.js'
import CentreLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import CentreLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
import CentreLeft3Chart from "@/components/echart/centerLeft/centerLeft3Chart";
import CentreLeft4Chart from "@/components/echart/centerLeft/centerLeft4Chart";
import CentreRight2Chart1 from "@/components/echart/centerRight/centerRightChart";
export default {
  data () {
    return {
      loading: true,
      device: [],
      lineList: [],
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      checkStatus: {
        0: '正常',
        1: '异常',
        2: '停机'
      },
      footerList: [],
      times: null,
      linePage: 1,
      pageTag: [],
      deviceNum: {},
      devicePreData: {},
      deviceStatusData: {},
      isFirst: true,  // 产线信息
      lineTimes: null,
      setLineTime: 0,
      isDeveiceStatusFirst: true,  // 设备状态
      runDeveiceStatusTime: null,
      setDeveiceStatus: 0,
      isDeveiceNumFirst: true,  // 设备数量
      runDeveiceNum: null,
      setDeveiceNum: 0,
      isDeveiceUseFirst: true,  // 设备使用负荷前五
      runDeveiceUse: null,
      setDeveiceUse: 0,
      isDeveicePerFirst: true,  // 设备性能
      runDeveicePer: null,
      setDeveicePer: 0,
      smtList: [
        {key: "SMT4", title: "SMT4"},
        {key: "SMT3", title: "SMT3"},
        {key: "SMT2", title: "SMT2"},
        {key: "SMT1", title: "SMT1"}
      ],
    };
  },
  components: {
      CentreLeft1Chart,
      CentreLeft2Chart,
      CentreLeft3Chart,
      CentreLeft4Chart,
      CentreRight2Chart1
  },
  watch: {
    isFirst(val) {
      if (!val) {
        this.refrehLine()
      }
    },
    isDeveiceStatusFirst(val) {
      if (!val) {
        this.refrehDeviceStatus()
      }
    },
    isDeveiceNumFirst(val) {
      if (!val) {
        this.refrehDeviceNum()
      }
    },
    isDeveiceUseFirst(val) {
      if (!val) {
        this.refrehDeviceUse()
      }
    },
    isDeveicePerFirst(val) {
      if (!val) {
        this.refrehDeveicePer()
      }
    }
  },
  created(){
    clearInterval(this.lineTimes)
    this.lineTimes = null
    clearInterval(this.runDeveiceStatusTime)
    this.runDeveiceStatusTime = null
    clearInterval(this.runDeveiceNum)
    this.runDeveiceNum = null
    clearInterval(this.runDeveiceUse)
    this.runDeveiceUse = null
    clearInterval(this.runDeveicePer)
    this.runDeveicePer = null
  },
  mounted () {
    this.timeFn();
    this.cancelLoading();
  
    // 设备使用负荷
    this.getDeviceLoadTop();
    // 获取设备数量
    this.getDeviceSum()
    // 获取设备性能
    this.getDevicePer()
    // 获取设备状态
    this.getDeviceStatus()
    if (this.isFirst) {
      // 获取产线信息
      // this.getLineList()
    }
    // 底部滚动字幕
    // this.getScrollText();
  },
  methods: {
    refrehDeveicePer() {
      this.runDeveicePer = setInterval(() => {
        this.getDevicePer()
      }, this.setDeveicePer)
    },
    refrehDeviceUse() {
      this.runDeveiceUse = setInterval(() => {
        this.getDeviceLoadTop()
      }, this.setDeveiceUse)
    },
    refrehDeviceNum() {
      this.runDeveiceNum = setInterval(() => {
        this.getDeviceSum()
      }, this.setDeveiceNum)
    },
    refrehDeviceStatus() {
      this.runDeveiceStatusTime = setInterval(() => {
        this.getDeviceStatus()
      }, this.setDeveiceStatus)
    },
    refrehLine() {
      let _this = this
      this.lineTimes = setInterval(() => {
        if (_this.linePage == _this.pageTag.length) {
          _this.linePage = 1
        } else {
          _this.linePage++
        }
        this.getLineList()
      }, this.setLineTime)
    },
    // 底部滚动字幕
    getScrollText() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetEbdDevMntMessage').then(res=>{
        if (res.data) {
          console.log(res.data)
          let arr = []
          for (let i = 0; i < 7; i++) {
            let t = res.data.Value1
            arr.push(t)
          }
          this.footerList = arr
        }
          
      })
    },
    // 获取设备状态
    _getDeviceStatus() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetStatu').then(res=>{ 
        if (res.data) {
          // console.log(res.data)
          this.deviceStatusData = res.data.Value1
          this.isDeveiceStatusFirst = false
          this.setDeveiceStatus = res.data.Value2
        }
      })
    },
    getDeviceStatus() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetStatu').then(res=>{ 
        const data = res.data
        if (data.statusCode == 200) {
          console.log(data.data)
          this.deviceStatusData = [data.data]
          this.isDeveiceStatusFirst = false
          this.setDeveiceStatus = 10000
        }
      })
    },
    // 获取设备性能
    _getDevicePer() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetPerformance').then(res=>{ 
        if (res.data) {
          this.devicePreData = res.data.Value1
          this.isDeveicePerFirst = false
          this.setDeveicePer = res.data.Value2
        }
      })
    },
    getDevicePer() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetPerformance').then(res=>{ 
        const data = res.data
        if (data.statusCode == 200) {
        // if (res.data) {
          this.devicePreData = [data.data]
          this.isDeveicePerFirst = false
          this.setDeveicePer = 10000
        }
      })
    },
    // 获取设备数量
    _getDeviceSum() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetQty').then(res=>{ 
        if (res.data) {
          // console.log(res.data)
          this.deviceNum = res.data.Value1[0]
          this.isDeveiceNumFirst = false
          this.setDeveiceNum = res.data.Value2
        }
      })
    },
    getDeviceSum() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetQty').then(res=>{ 
        const data = res.data
        if (data.statusCode == 200) {
          this.deviceNum = data.data
          this.isDeveiceNumFirst = false
          this.setDeveiceNum = 10000
        }
      })
    },
    
    // 获取产线信息
    getLineList() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetWorkCenter?pages='+this.linePage).then(res=>{
        if (res.data) {
          // console.log(res.data)
          let arr = []
          for (let i = 1; i <= res.data.Value1; i++) {
            if (i == 1) {
              arr.push('1-4')
            } else {
              let s = i * 4
              let k = i * 4 - 3
              let texts = k + "-" + s
              arr.push(texts)
            }
          }
          // 页码标签
          this.pageTag = arr
          this.lineList = res.data.Value2
          this.isFirst = false
          this.setLineTime = res.data.Value3
          // if (!this.isFirst) {
          //   this.refrehLine()
          // }
        }
      })
    },
  
    // 获取设备使用负荷前五
    _getDeviceLoadTop() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetServiceLoadTopFive').then(res=>{
          if (res.data) {
            this.device = res.data.Value1.Table
            this.isDeveiceUseFirst = false
            this.setDeveiceUse = res.data.Value2 
          }
      })
    },
    getDeviceLoadTop() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetServiceLoadTopFive').then(res=>{
        const data = res.data
        if (data.statusCode == 200) {
            this.device = data.data
            this.isDeveiceUseFirst = false
            this.setDeveiceUse = 10000
          }
      })
    },
    loadPercent(x, y) {
        let rotate = (x / y) * 360
        let rotateRight = 0
        var rotateLeft = 0
        if (rotate < 180) {
            rotateRight = rotate + (-45)
        } else {
            rotateRight = 135
            rotateLeft = (rotate - 180 - 45)
        }
        if (rotateLeft == 0) {
          rotateLeft = -45
        }
        let arr = [rotateLeft, rotateRight]
        return arr

    },
    timeFn () {
      this.times = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/indexDevice.scss';
</style>