<template>
  <div style="width:100%;height:100%">
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="2.75rem"
      width="3.25rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        let labelOption = {
          show: false,
          formatter: "{c}  {name|{a}}",
          fontSize: 16,
          rich: {
            name: {
              textBorderColor: "#fff",
            },
          },
        }
        this.options = {
          color: ["#19E0E7", "#F92D28"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          // 调整图表位置
          grid: {
            x: 40
          },
          toolbox: {
            show: false,
            orient: "vertical",
            left: "right",
            top: "center",
            feature: {
              mark: { show: false },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar", "stack", "tiled"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          calculable: true,
          legend: {
            borderColor: ["red", "yellow"],
            orient: "horizontal",
            left: "center",
            data: ["使用率", "异常率"],
            icon: "rect",
            itemWidth: 10,
            itemHeight: 8,
            textStyle: {
              fontSize: 9
            },
            top: 20
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              data: newData.xData,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 10
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
              splitLine: {
                lineStyle: {
                  color: ["#315070"],
                  width: 1,
                  type: "solid",
                },
              },
            },
          ],
          series: [
            {
              name: "使用率",
              type: "bar",
              barGap: 0,
              label: labelOption,
              data: newData.series1Data,
              barWidth: 10,
              itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      color: 'rgba(25, 224, 231, 0.9)'
                    },{
                      offset: 1,
                      color: 'rgba(25, 224, 231, 0.2)'
                    }],
                    globalCoord: false
                  }
              }
            },
            {
              name: "异常率",
              type: "bar",
              label: labelOption,
              data: newData.series2Data,
              barWidth: 10,
              itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      color: 'rgba(249, 45, 40, 0.9)'
                    },{
                      offset: 1,
                      color: 'rgba(249, 45, 40, 0.2)'
                    }],
                    globalCoord: false
                  }
              }
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>