<template>
  <div class="chart-div">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        lengendData: [],
        xdata: [],
        seriesData: [],
        colorArr: [
          
          [{offset: 0, color: 'rgba(255, 176, 22, 0.9)'},{offset: 1, color: 'rgba(255, 176, 22, 0.2)'}],
          [{offset: 0, color: 'rgba(28, 250, 254, 0.9)'},{offset: 1, color: 'rgba(28, 250, 254, 0.2)'}],
          [{offset: 0, color: 'rgba(249, 45, 40, 0.9)'},{offset: 1, color: 'rgba(249, 45, 40, 0.2)'}],
          
        ]
      },
      isDeviceBad: true,
      runDeviceBad: null,
      setDeviceBad: 0,
    }
  },
  components: {
    Chart,
  },
  watch: {
    isDeviceBad(val) {
      if (!val) {
        this.refrehData()
      }
    },
  },
  created() {
    clearInterval(this.runDeviceBad)
    this.runDeviceBad = null
  },
  mounted () {
    this.getData()
  },
  methods: {
    refrehData() {
      this.runDeviceBad = setInterval(() => {
        this.getData()
      }, this.setDeviceBad)
    },
    // 设备异常趋势统计
    _getData() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetAbnormalTrendStatistics').then(res=>{
          if (res.data) {
            this.cdata.lengendData = res.data.Value2
            this.cdata.xdata = res.data.Value1[0]
            let arr = []
            for (let i = 1; i < res.data.Value1.length; i++) {
              arr.push({
                name: res.data.Value2[i - 1],
                data: res.data.Value1[i],
                type: 'line',
                symbol: 'none'
              })
            }
            for (let k = 0; k < this.cdata.colorArr.length; k++) {
              if (arr[k]) {
                arr[k].areaStyle = {
                  normal: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: this.cdata.colorArr[k],
                      globalCoord: false
                    }
                  }
                }
              }
              
            }
            this.cdata.seriesData = arr
            this.isDeviceBad = false
            this.setDeviceBad = res.data.Value3
          }
      })
    },
    getData() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetAbnormalTrendStatistics').then(res=>{
        const data = res.data
        if (data?.statusCode == 200) {
          // if (res.data) {
            this.cdata.lengendData = data.data.Value2
            this.cdata.xdata = data.data.Value1[0]
            let arr = []
            for (let i = 1; i < data.data.Value1.length; i++) {
              arr.push({
                name: data.data.Value2[i - 1],
                data: data.data.Value1[i],
                type: 'line',
                symbol: 'none'
              })
            }
            for (let k = 0; k < this.cdata.colorArr.length; k++) {
              if (arr[k]) {
                arr[k].areaStyle = {
                  normal: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: this.cdata.colorArr[k],
                      globalCoord: false
                    }
                  }
                }
              }
              
            }
            this.cdata.seriesData = arr
            this.isDeviceBad = false
            this.setDeviceBad = 10000
          }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-div {
    width: 100%;
    height: 100%;
    padding: 0.5rem 0.2rem 0.2rem 0.5rem;
  }
</style>