<template>
  <div class="chart-div">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props: ['data'],
  data () {
    return {
      cdata: {
        value: 0
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    this.cdata.value = this.data[0].BadRate
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .chart-div {
    width: 100%;
    height: 100%;
    // padding-top: 0.2rem;
    // padding-left: 0.25rem;
  }
</style>