<template>
  <div class="chart-div">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        legendData: [],
        insideData: [],
        outsideData: []
      },
      isFirst: true,
      runTime: null,
      setRunTime: 5000
    }
  },
  components: {
    Chart,
  },
  watch: {
    isFirst(val) {
      if (!val) {
        this.refrehData()
      }
    }
  },
  created() {
    clearInterval(this.runTime)
    this.runTime = null
  },
  mounted () {
    this.getData()
  },
  methods: {
    refrehData() {
      this.runTime = setInterval(() => {
        this.getData()
      }, this.setRunTime)
    },
    // 设备故障统计
    _getData() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetFaultStatistics').then(res=>{
        if (res.data) {
          this.cdata.legendData = res.data.Value1
          this.cdata.insideData = res.data.Value2.InsideData
          this.cdata.outsideData = res.data.Value2.OutsideData
          this.isFirst = false
          this.setRunTime = res.data.Value3
        }
      })
    },
    getData(){
      this.getInsideData();
      this.getOutsideData();
      this.getLegendData();
    },
    getInsideData() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetFaultStatisticsInsideData').then(res=>{
        const data = res.data
        if (data?.statusCode == 200) {
          this.cdata.insideData = data.data 
          this.isFirst = false
          this.setRunTime = 10000 
        }
      })
    },
    getOutsideData() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetFaultStatisticsOutsideData').then(res=>{
        const data = res.data
        if (data?.statusCode == 200) {
          this.cdata.outsideData = data.data 
          this.isFirst = false
          this.setRunTime = 10000 
        }
      })
    },
    getLegendData() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetFaultStatisticsStatus').then(res=>{
        const data = res.data
        if (data?.statusCode == 200) {
          this.cdata.legendData = data.data
          this.isFirst = false
          this.setRunTime = 10000 
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-div {
    width: 100%;
    height: 100%;
    padding: 0.2rem 0;
  }
</style>