<template>
  <div style="width:100%;height:100%">
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="2.75rem"
      width="3.25rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: ["#F92D28", "#1CFAFE", "#FFB016"],
          legend: {
            data: newData.lengendData
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: newData.xdata,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
          },
          yAxis: {
              type: 'value',
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#fff",
                },
              },
              splitLine: {
                lineStyle: {
                  color: ["#FFB016"],
                  width: 1,
                  type: "solid",
                },
              },
          },
          series: newData.seriesData
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>