<template>
  <div style="width: 300%; height: 300%;transform: scale(0.4);position: absolute;top:-1.8rem;left:-1.7rem;">
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="2.75rem"
      width="3.25rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        let value = newData.value;
        let color = new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: "#D8FD36",
          },
          {
            offset: 1,
            color: "#26EC10",
          },
        ]);
        let $echarts = this.$echarts
        this.options = {
          series: [
            {
              name: "信用分",
              type: "gauge",
              startAngle: 200,
              endAngle: -20,
              min: 0,
              max: 100,
              radius: "60%",
              axisLine: {
                show: true,
                lineStyle: {
                  color: [
                    [
                      100,
                      new $echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                          offset: 0,
                          color: "#FB3F52",
                        },
                        {
                          offset: 1,
                          color: "#3BF37A",
                        },
                      ]),
                    ],
                    [1, "rgba(225,225,225,0.4)"],
                  ],
                  width: 1,
                  
                  opacity: 1,
                },
              },
              title: {
                show: false,
              },
              detail: {
                show: true,
                offsetCenter: [0, "80%"],
                color: "#D6F1FF",
                fontSize: 22,
                formatter: function (value) {
                  return (
                    "运行率{value|" +
                    value.toFixed(0) +
                    "}{unit|%}"
                  );
                },
                // 下面的百分比
                rich: {
                  value: {
                    fontSize: 22,
                    color: "#FFDE00",
                  },
                  unit: {
                    fontSize: 22,
                    color: "#FFDE00",
                  },
                },
              },
              // 内圈刻度
              axisTick: {
                length: 4,
                splitNumber: 2,
                lineStyle: {
                  color: "#999",
                },
              },
              splitLine: {
                length: 10,
                lineStyle: {
                  color: new $echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: "#FB3F52",
                    },
                    {
                      offset: 1,
                      color: "#3BF37A",
                    },
                  ]),
                },
              },
              axisLabel: {
                color: "#D6F1FF",
                fontSize: 10,
              },
              // 指针
              pointer: {
                // show:false,
                width: 10,
                length: 65,
              },
              itemStyle: {
                color: color,
                shadowColor: "rgba(0,138,255,0.45)",
                shadowBlur: 6,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
              },
              markPoint: {
                // symbol:'circle',
                // symbolSize:15,
                // itemStyle: {
                //     color: "#fff"
                // },
                data: [
                  // 指针的那个圆
                  {
                    x: "50%",
                    y: "50%",
                    symbol: "circle",
                    symbolSize: 30,
                    itemStyle: {
                      color: color,
                    },
                  },
                  {
                    x: "50%",
                    y: "50%",
                    symbol: "circle",
                    symbolSize: 12,
                    itemStyle: {
                      color: "#fff",
                    },
                  },
                ],
              },
              data: [
                {
                  value: value,
                  name: "年售电量情况",
                },
              ],
            },
            // 最外围的刻度圈
            {
              name: "已到人数",
              type: "gauge",
              radius: "75%",
              startAngle: 200,
              endAngle: -20,
              min: 0,
              max: 100,
              title: {
                show: false,
              },
              detail: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  width: 10,
                  color: [
                    [value / 100, color],
                    [1, "#35375C"],
                  ],
                  // shadowColor: 'rgba(0,138,255,0.35)',
                  // shadowBlur: 5,
                },
              },
              axisTick: {
                show: true,
                length: 10,
                splitNumber: 2,
                lineStyle: {
                  color: "#192568",
                  width: 3,
                },
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              pointer: {
                show: false,
              },
              itemStyle: {
                normal: {
                  color: "#35375C",
                },
              },
              data: [
                {
                  value: value,
                  name: "年售电量情况",
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>