<template>
  <div class="chart-div">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: [],
        series1Data: [],
        series2Data: []
      },
      isFirst: true,
      runTime: null,
      setRunTime: 5000
    }
  },
  components: {
    Chart,
  },
  watch: {
    isFirst(val) {
      if (!val) {
        this.refrehData()
      }
    }
  },
  created() {
    clearInterval(this.runTime)
    this.runTime = null
  },
  mounted () {
    this.getData()
  },
  methods: {
    refrehData() {
      this.runTime = setInterval(() => {
        this.getData()
      }, this.setRunTime)
    },
    // 设备使用情况统计
    _getData() {
      this.$http.get('/EbdDevMntService/EbdDevMnt/GetUsageStatistics').then(res=>{
          if (res.data) {
            this.cdata.xData = res.data.Value1[0]
            this.cdata.series1Data = res.data.Value1[1]
            this.cdata.series2Data = res.data.Value1[2]
            this.setRunTime = res.data.Value2
          }
      })
    },
    getData() {
      this.$http.get('/ElectronicBoard/electronicBoard/GetUsageStatistics').then(res=>{
        const data = res.data
          if (data?.statusCode == 200) {
            this.cdata.xData = data.data[0]
            this.cdata.series1Data = data.data[1]
            this.cdata.series2Data = data.data[2]
            this.setRunTime = 10000
          }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-div {
    width: 100%;
    height: 100%;
    // padding-top: 0.2rem;
    // padding-left: 0.25rem;
  }
</style>