<template>
  <div style="width:100%;height:100%">
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="2.75rem"
      width="3.25rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: [
            "#224354",
            "#0D8100",
            "#0077B3",
            "#FFB016",
            "#F92D28",
            "#98B994",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            left: "center",
            data: newData.legendData,
            icon: "rect",
            itemWidth: 10,
            itemHeight: 8,
            textStyle: {
              fontSize: 9
            }
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              selectedMode: "single",
              radius: [0, "30%"],
              itemStyle: {
                  normal: {
                      borderColor: '#fff',
                      borderWidth: 2
                  }
              },
              label: {
                position: "inner",
              },
              labelLine: {
                show: false,
              },
              data: newData.insideData
            },{
              name: "访问来源",
              type: "pie",
              radius: ["40%", "55%"],
              data: newData.outsideData,
              labelLine: {
                  normal: {
                      length: 10,
                      length2: 60,
                      lineStyle: {
                          color: '#e6e6e6'
                      }
                  }
              },
              label: {
                normal: {
                    formatter: params => {
                        return (
                            '{name|' + params.name + '}{value|' + (params.value) + '个}\n\n{percent|'+ params.percent.toFixed(1) +'%}'
                        );
                    },
                    borderWidth: 20,
                    borderRadius: 4,
                    padding: [20, -60, 17,],
                    rich: {
                        color: '#333',
                        icon: {
                            fontSize: 16
                        },
                    }
                }
              }
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>